<!--
 * @Descripttion:
 * @version:
 * @Author: 张天鹏
 * @Date: 2020-12-09 17:00:56
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-12-10 14:50:54
-->
<template>
  <div class="container">
    <!-- 内容 -->
    <div class="content">
      <div v-for="(item,index) in ZNYXlist"
           class="menu_box"
           :key="'one'+index">
        <div class="title">
          <h3>{{ item.mkname }}</h3>
        </div>
        <div class="menu">
          <div class="submenu"
               v-for="(it,idx) in item.children"
               :key="idx">
            <router-link tag="div"
                         :to="it.mklj?it.mklj:''">
              <div class="icon">
                <img :src="ossurl + it.micon"
                     alt="icons" />
              </div>
              <p class="menu_title">{{it.mkname}}</p>
            </router-link>
          </div>
        </div>
      </div>
      <div v-for="(item,i) in KHFWlist"
           class="menu_box"
           :key="'two'+i">
        <div class="title">
          <h3>{{ item.mkname }}</h3>
        </div>
        <div class="menu">
          <div class="item"
               v-for="(it,inx) in item.children"
               :key="'two'+inx">
            <router-link tag="div"
                         :to="it.mklj?it.mklj:''">
              <div class="icon">
                <img :src="ossurl + it.bicon"
                     alt="icons" />
              </div>
              <p class="menu_title">{{it.mkname}}</p>
              <p class="p_text">{{it.mkinfo}}</p>
            </router-link>
            <div v-show="it.mkname == '智能提醒'"
                 class="showNoRead">
              <div class="red"
                   v-if="jrdata.num != '0' && parseInt(jrdata.num) < 99">{{jrdata.num }}</div>
              <div class="red"
                   v-if="parseInt(jrdata.num) > 99">...</div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="(item,ix) in TDGLlist"
           class="menu_box"
           :key="'t'+ix">
        <div class="title">
          <h3>{{ item.mkname }}</h3>
        </div>
        <div class="menu-tdgl">
          <div class="submenu"
               v-for="(it, ixt) of item.children"
               :key="ixt">
            <router-link tag="div"
                         :to="it.mklj?it.mklj:''"
                         class="bigtb">
              <h4 class="menu_title">{{ it.mkname }}</h4>
              <p>{{ it.mkparms }}</p>
              <div class="tolook">
                <div class="look_text">马上查看</div>
                <div class="look_icon">
                  <!-- <div class="icon"></div> -->
                  <img src="@/assets/newskin/bo.png" alt="">
                </div>
              </div>
              <div class="rbicon">
                <img :src="ossurl + it.bicon"
                     alt="icon" />
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="footer"></div>
    <div>
      <nhsx></nhsx>
    </div>
  </div>
</template>
<script>
import { wdmsg } from '@/api/basic/index'
import { getStorage } from '@/lib/util'
import { mapGetters } from 'vuex'
import nhsx from '@/views/basic/home/component/nhsx'
export default {
  components: { nhsx },
  data () {
    return {
      getUser: {},
      getMenulist: [],
      getBossurl: '',
      jrdata: { num: 0 },
      ossurl: getStorage('ossurl', ''),
      ZNYXlist: [{ mkname: '智能营销', children: [] }],
      KHFWlist: [{ mkname: '客户服务', children: [] }],
      TDGLlist: [{ mkname: '团队管理', children: [] }],
      color: '#666666',
      bgshow: false
    }
  },
  computed: {
    ...mapGetters(['getossurl'])
  },
  created () {
    this.getUser = getStorage('u_s', {})
    this.getMenulist = getStorage('m_l', '')
    this.getBossurl = getStorage('tbossurl', '')
    this.getMenulist.map(item => {
      item.children.map(it => {
        if (
          it.id == 'PN10002' ||
          it.id == 'PN10003' ||
          it.id == 'PN10008' ||
          it.id == 'PN10009'
        ) {
          this.ZNYXlist[0].children.push(it)
        }
        if (
          it.id == 'PN10004' ||
          it.id == 'PN10005' ||
          it.id == 'PN10006' ||
          it.id == 'PN10007' ||
          it.id == 'PN10031' || it.id == 'PN10011'
        ) {
          it.id == 'PN10004' ? (it.mkinfo = '专属精美海报') : ''
          it.id == 'PN10005' ? (it.mkinfo = '个性化实时天气') : ''
          it.id == 'PN10006' ? (it.mkinfo = '在线也能发贺卡') : ''
          it.id == 'PN10007' ? (it.mkinfo = '微信高效邀约') : ''
          it.id == 'PN10031' ? (it.mkinfo = '消息提醒早知道') : ''
          it.id == 'PN10011' ? (it.mkinfo = '双向沟通不受限') : ''
          this.KHFWlist[0].children.push(it)
        }
        if (it.id == 'PN10012' || it.id == 'PN10013') {
          it.id == 'PN10012' ? (it.mkparms = '团队实时播报') : ''
          it.id == 'PN10013' ? (it.mkparms = '团队工作分析') : ''
          this.TDGLlist[0].children.push(it)
        }
      })
    })
    // this.getdata()
  },
  methods: {
    // getdata () {
    //   wdmsg().then(res => {
    //     this.jrdata = res.data.data
    //   })
    // },
    tomorning () {
      this.$router.push('/morning')
    },
    tozt () {
      this.$router.push('/bszt')
    },
    tojys () {
      window.location.href = 'http://dc.ishdr.com/dred/index.html'
    },
    closemc () {
      this.bgshow = false
    }
  }
}
</script>
<style lang="stylus" scoped>
bg-color = #ffffff;
bj-color = #efefef;
font-color = #989898;
changeCol(#008070);

.container {
  height: 100%;
  background-color: bg-color;
  overflow-y: auto;

  .content {
    .menu_box {
      padding: 0 0.25rem 0.4rem;
      border-bottom: 0.3rem solid bj-color;

      &:last-child {
        border-bottom: none;
      }

      .title {
        line-height: 1.4rem;

        h3 {
          color: #3d3d3d;
        }
      }

      .menu {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
        padding: 0 0.5rem;

        .submenu {
          width: 25%;
          text-align: center;
          margin-top: 40px;

          .icon {
            width: 0.9rem;

            // height: 0.8rem;
            margin: 0 auto 20px auto;

            .red {
              width: 0.6rem;
              height: 0.6rem;
              line-height: 0.6rem;
              text-align: center;
              color: #fff;
              font-size: 0.35rem;
              border-radius: 50%;
              display: inline-block;
              background: red;
              position: relative;
              bottom: 0.3rem;
              right: 0.4rem;
            }
          }

          .menu_title {
            color: #4f4f4f;
            font-size: 35px;
          }
        }

        .item {
          width: 32%;
          height: 3rem;
          text-align: center;
          margin-bottom: 40px;
          margin-right: 2%;
          box-shadow: #f0f0f0 0 0 20px;
          position: relative;

          .showNoRead {
            position: absolute;
            top: 40px;
            right: 0;
          }

          &:nth-child(3n+3) {
            margin-right: 0;
          }

          .icon {
            width: 0.8rem;
            height: 0.8rem;
            margin: 0.45rem auto 0.2rem auto;
          }

          .menu_title {
            color: #4f4f4f;
            font-size: 36px;
          }

          .p_text {
            color: #A2A2A2;
            line-height: 0.8rem;
            font-size: 26px;
            letter-spacing: 2px;
          }
        }
      }
    }
  }
}

.menu-tdgl {
  display: flex;
  margin: 0 0.4rem;

  .submenu {
    width: 48%;
    border-radius: 10px;
    background-color: #F5F8FD;
    margin-top: 30px;
    padding: 25px 0 0 28px;
    position: relative;
    height: 200px;
    background: #f8f8fa;

    &:nth-child(2n+1) {
      margin-right: 4%;
    }

    h4 {
      font-size: 36px;
      color: #4f4f4f;
    }

    p {
      font-size: 25px;
      color: #C2BEBD;
    }

    .tolook {
      display: flex;
      margin-top: 20px;

      .look_text {
        font-size: 29px;
        color: gray;
      }

      .look_icon {
        width: 30px;
        height: 30px;
        // background: #ffffff;
        border-radius: 50%;
        margin-left: 10px;

        /* box-shadow: 0.2rem 0.1rem 0.5rem 0rem rgba(33, 130, 111, 0.25); */
        /* border: 1px solid #000; */
        .icon {
          margin: 6px auto;
          width: 0;
          height: 0;
          border: 10px solid transparent;
          border-left-color: #FC5D3D;
          position: relative;
          left: 6px;
        }
        .icons{
          height auto
        }
      }
    }

    .rbicon {
      width: 1rem;
      position: absolute;
      bottom: 0.35rem;
      right: 0.1rem;
      img{
        width 70%
        height auto
      }
    }
  }
}

.red {
  width: 0.5rem;
  height: 0.5rem;
  line-height: 0.5rem;
  text-align: center;
  color: #fff;
  font-size: 0.3rem;
  border-radius: 50%;
  display: inline-block;
  background: red;
  position: relative;
  bottom: 0.3rem;
  right: 0.4rem;
}
.footer{
  height 10vh
}
</style>
